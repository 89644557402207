import React from 'react';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFingerprint,
    faBookOpen,
    faWindowClose,
    faSave,
    faCheck,
    faPlay,
    faPencilAlt,
    faFileDownload,
    faTrashAlt,
    faCopyright,
    faHashtag,
    faUser,
    faLock
} from '@fortawesome/free-solid-svg-icons';

import { H5PEditorUI, H5PPlayerUI } from '@lumieducation/h5p-react';

import { IContentListEntry, IContentService } from '../services/ContentService';
import './ContentListEntryComponent.css';

export default class ContentListEntryComponent extends React.Component<{
    contentService: IContentService;
    data: IContentListEntry;
    onDelete: (content: IContentListEntry) => void;
    onDiscard: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry) => void;
    generateDownloadLink: (contentId: string) => string;
}> {
    private parentUrl: string;
    constructor(props: {
        contentService: IContentService;
        data: IContentListEntry;
        onDiscard: (content: IContentListEntry) => void;
        onDelete: (content: IContentListEntry) => void;
        onSaved: (data: IContentListEntry) => void;
        generateDownloadLink: (contentId: string) => string;
    }) {
        super(props);
        this.parentUrl = process.env.REACT_APP_PARENT_URL ?? '';
        this.state = {
            editing: true,
            playing: true,
            saving: false,
            saved: false,
            loading: true,
            saveErrorMessage: '',
            saveError: false,
            showingCustomCopyright: false,
            showContextIdModal: false,
            showAsUserIdModal: false,
            readOnlyState: false
        };
        this.h5pEditor = React.createRef();
        this.saveButton = React.createRef();
        this.h5pPlayer = React.createRef();
        this.contextIdInput = React.createRef();
        this.asUserIdSelect = React.createRef();
    }
    public async componentDidMount(): Promise<void> {
        //this.edit();
        window.addEventListener('message', this.receiveMessage, false);
    }
    public componentWillUnmount() {
        window.removeEventListener('message', this.receiveMessage, false);
    }
    private receiveMessage = (event) => {
        if (!this.allowOrigin(event.origin)) {
            return;
        }
        if (event.data.type === 'triggerSave') {
            this.save();
        }
    }
    private allowOrigin(url: string){
        if (url.includes('4200') || url.includes('lms.mschool.edu.vn') || url.includes('lms2.mschool.edu.vn') || url.includes('nguyenkienan'))
            return true;
    }
    public state: {
        asUserId?: string;
        contextId?: string;
        editing: boolean;
        loading: boolean;
        playing: boolean;
        readOnlyState: boolean;
        saved: boolean;
        saveError: boolean;
        saveErrorMessage: string;
        saving: boolean;
        showAsUserIdModal: boolean;
        showContextIdModal: boolean;
        showingCustomCopyright: boolean;
    };

    private h5pPlayer: React.RefObject<H5PPlayerUI>;
    private h5pEditor: React.RefObject<H5PEditorUI>;
    private saveButton: React.RefObject<HTMLButtonElement>;
    private contextIdInput: React.RefObject<HTMLInputElement>;
    private asUserIdSelect: React.RefObject<HTMLSelectElement>;

    public render(): React.ReactNode {
        // @ts-ignore
        return (
            <ListGroupItem
                key={
                    this.props.data.originalNewKey ?? this.props.data.contentId
                }
            >
                <Container>
                    <Row>

                        {(this.state.editing && this.props.data.contentId !== '-') ? (
                            <Col className="p-2" lg="auto">
                                <Overlay
                                    target={this.saveButton.current}
                                    show={this.state.saveError}
                                    placement="right"
                                >
                                    <Tooltip id="error-tooltip">
                                        {this.state.saveErrorMessage}
                                    </Tooltip>
                                </Overlay>
                                <Button
                                    ref={this.saveButton}
                                    variant="success"
                                    className={
                                        this.state.saving || this.state.loading
                                            ? 'disabled'
                                            : ''
                                    }
                                    disabled={
                                        this.state.saving || this.state.loading
                                    }
                                    onClick={() => this.save()}
                                >
                                    {this.state.saving ? (
                                        <div
                                            className="spinner-border spinner-border-sm m-1 align-middle"
                                            role="status"
                                        ></div>
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faSave}
                                            className="me-2"
                                        />
                                    )}{' '}
                                    Lưu bài giảng{' '}
                                    {this.state.saved ? (
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="me-2"
                                        />
                                    ) : undefined}
                                </Button>
                            </Col>
                        ) : undefined}
                        {/*{this.state.editing && !this.isNew() ? (
                            <Col className="p-2" lg="auto">
                                <Button
                                    variant="light"
                                    onClick={() => this.close()}
                                >
                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        className="me-2"
                                    />
                                    close editor
                                </Button>
                            </Col>
                        ) : undefined}*/}
                        {/*{this.state.editing && this.isNew() ? (
                            <Col className="p-2" lg="auto">
                                <Button
                                    variant="light"
                                    onClick={() =>
                                        this.props.onDiscard(this.props.data)
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        className="me-2"
                                    />
                                    Đóng
                                </Button>
                            </Col>
                        ) : undefined}*/}
                        <Col className="p-2 d-flex" lg="2">
                            <Row className="small">
                                <Col
                                    className="me-2 d-flex align-items-center"
                                    lg="auto"
                                >
                                    <FontAwesomeIcon
                                        icon={faFingerprint}
                                        className="me-1"
                                    />
                                    {this.props.data.contentId === 'new' ? 'Tạo mới nội dung' : ('Sửa nội dung : ' + this.props.data.contentId)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                { (this.state.editing && this.props.data.contentId !== '-') ? (
                    <div
                        className={
                            this.props.data.contentId !== 'new' &&
                            this.state.loading
                                ? 'loading'
                                : ''
                        }
                    >
                        <H5PEditorUI
                            ref={this.h5pEditor}
                            contentId={this.props.data.contentId}
                            loadContentCallback={
                                this.props.contentService.getEdit
                            }
                            saveContentCallback={this.props.contentService.save}
                            onSaved={this.onSaved}
                            onLoaded={this.onEditorLoaded}
                            onSaveError={this.onSaveError}
                        />
                    </div>
                ) : undefined}
            </ListGroupItem>
        );
    }

    protected play() {
        this.setState({ editing: false, playing: true });
    }

    protected edit() {
        this.setState({ editing: true, playing: false });
    }

    protected close() {
        this.setState({ editing: false, playing: false });
    }

    protected showCopyrightCustom() {
        this.setState({ showingCustomCopyright: true });
    }

    protected showContextIdModal() {
        this.setState({ showContextIdModal: true });
        setTimeout(() => {
            this.contextIdInput.current?.focus();
        }, 100);
    }

    protected closeContextIdModal() {
        this.setState({ showContextIdModal: false });
    }

    protected showAsUserIdModal() {
        this.setState({ showAsUserIdModal: true });
        setTimeout(() => {
            this.asUserIdSelect.current?.focus();
        }, 100);
    }

    protected async save() {
        this.setState({ saving: true });
        try {
            const returnData = await this.h5pEditor.current?.save();
            const parentUrl =  process.env.REACT_APP_PARENT_URL ?? '';
            const payload = { key: 'saveContentH5P', returnData }; // Thay thế bằng tham số thực tế của bạn
            //console.log(payload);
            window.parent.postMessage({ type: 'callParentFunction', payload }, parentUrl);
            if (returnData) {
                await this.props.onSaved({
                    contentId: returnData.contentId,
                    mainLibrary: returnData.metadata.mainLibrary,
                    title: returnData.metadata.title,
                    originalNewKey: this.props.data.originalNewKey,
                    role: this.props.data.role
                });
            }
        } catch (error) {
            // We ignore the error, as we subscribe to the 'save-error' and
            // 'validation-error' events.
        }
    }

    protected onSaveError = async (event) => {
        console.log('onSaveError', event);
        this.setState({
            saving: false,
            saved: false,
            saveError: true,
            saveErrorMessage: event
        });
        setTimeout(() => {
            this.setState({
                saveError: false
            });
        }, 5000);
    };

    protected onSaved = async (event) => {
        this.setState({
            saving: false,
            saved: true
        });
        setTimeout(() => {
            this.setState({ saved: false });
        }, 3000);
    };

    protected onEditorLoaded = () => {
        this.setState({ loading: false });
    };

    protected setContextId = () => {
        this.setState({
            contextId: this.contextIdInput.current?.value,
            showContextIdModal: false
        });
    };

    protected setAsUserId = () => {
        this.setState({
            asUserId: this.asUserIdSelect.current?.value,
            showAsUserIdModal: false
        });
    };

    private isNew() {
        return this.props.data.contentId === 'new';
    }
}
